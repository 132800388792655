import * as classNames from 'classnames';
import * as React from 'react';
import styles from './button.module.scss';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: string;
  label: string;
  variant?: 'primary' | 'secondary' | 'danger';
  size?: 'sm' | 'lg';
}

export const Button = ({
  className,
  icon,
  label = 'Button',
  variant = 'primary',
  size = 'sm',
  ...rest
}: ButtonProps) => {
  return (
    <button
      className={classNames(
        className,
        styles.button,
        styles[variant],
        styles[size]
      )}
      {...rest}
    >
      {icon ? <img className={styles.icon} src={icon} /> : null}
      <div className={styles.label}>{label}</div>
    </button>
  );
};
