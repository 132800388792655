// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".alert-container--rYX8IawgPKYuwYl7QN1b{width:100%;padding-bottom:18px}.select-container--OlfmmdyG7eZccpXsWwP7{padding-bottom:18px}.input-file-container--CsBhTzvoYVjuSu9jQd9d{padding-bottom:12px}", "",{"version":3,"sources":["webpack://./src/components/dashboad/upload-modal.module.scss"],"names":[],"mappings":"AAEA,uCACI,UAAA,CACA,mBAAA,CAGJ,wCACI,mBAAA,CAGJ,4CACI,mBAAA","sourcesContent":["@import 'src/scss/variables';\n\n.alert-container{\n    width: 100%;\n    padding-bottom: 18px;\n  }\n\n.select-container {\n    padding-bottom: 18px;\n}\n\n.input-file-container {\n    padding-bottom: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert-container": "alert-container--rYX8IawgPKYuwYl7QN1b",
	"alertContainer": "alert-container--rYX8IawgPKYuwYl7QN1b",
	"select-container": "select-container--OlfmmdyG7eZccpXsWwP7",
	"selectContainer": "select-container--OlfmmdyG7eZccpXsWwP7",
	"input-file-container": "input-file-container--CsBhTzvoYVjuSu9jQd9d",
	"inputFileContainer": "input-file-container--CsBhTzvoYVjuSu9jQd9d"
};
export default ___CSS_LOADER_EXPORT___;
