import * as React from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Animal, AnimalRelation, Sample, Test } from '../../shared/types';

interface FlyoutProps {
  animal?: Animal;
  closeFlyout: () => void;
}

function Flyout(props: FlyoutProps) {
  const [flyoutOpen, setFlyoutOpen] = React.useState<boolean>(false);
  const [selectedSample, setSelectedSample] = React.useState<
    Sample | undefined
  >(undefined);

  React.useEffect(() => {
    setFlyoutOpen(props.animal !== undefined);
    if (props.animal?.samples) {
      setSelectedSample(props.animal.samples[0]);
    }
  }, [props.animal]);

  const handleCLoseFlyout = () => {
    setFlyoutOpen(false);
    setTimeout(() => {
      props.closeFlyout();
    }, 500);
  };

  const progenyTableColumnHelper = createColumnHelper<AnimalRelation>();

  const progenyColumns = [
    progenyTableColumnHelper.accessor('name', {
      header: () => 'Progeny Name',
      cell: (context) => context.getValue(),
    }),
    progenyTableColumnHelper.accessor('verified', {
      header: () => 'Verification Status',
      cell: (context) => (
        <div>
          {context.getValue() ? (
            <img src="./images/verified-tag.svg" />
          ) : (
            'Unverified'
          )}
        </div>
      ),
    }),
    progenyTableColumnHelper.display({
      id: 'buttons',
      header: () => <></>,
      cell: (context) => (
        <div>
          {/* <button className="fancy-button green">
            <img src="./images/download-icon.svg" />
            Download Test Results
          </button> */}
        </div>
      ),
    }),
  ];

  const progenyTable = useReactTable({
    data: props?.animal?.progeny ?? [],
    columns: progenyColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  const testsColumnHelper = createColumnHelper<Test>();

  const testColumns = [
    testsColumnHelper.accessor('status', {
      header: () => 'Status',
      cell: (context) => <div>{context.getValue()}</div>,
    }),
    testsColumnHelper.accessor('name', {
      header: () => 'Name',
      cell: (context) => <div>{context.getValue()}</div>,
    }),
    testsColumnHelper.accessor('results', {
      header: () => 'Results',
      cell: (context) => <div>--</div>,
    }),
  ];

  const testTable = useReactTable({
    data: selectedSample?.tests ?? [],
    columns: testColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div
        className={`flyout-container${
          props.animal && flyoutOpen ? ' show' : ' hide'
        }`}
        onClick={() => handleCLoseFlyout()}
      />
      <div
        className={`flyout${props.animal && flyoutOpen ? ' show' : ' hide'}`}
      >
        {props.animal && (
          <>
            <div className="flyout-header">
              Animal Details
              <span onClick={() => handleCLoseFlyout()}>
                <img src="./images/close-button.svg" />
              </span>
            </div>
            <div className="flyout-body">
              <div className="flyout-body-topper">
                <h4>Data</h4>
                {/* <button className="fancy-button grey">
                  <img src="./images/edit-icon.svg" />
                  Edit Animal
                </button> */}
              </div>
              <div className="input-row-container">
                <div style={{ width: '25%' }}>
                  <label>Animal ID</label>
                  <input type="text" value={props.animal.id} disabled />
                </div>
                <div style={{ width: '75%' }}>
                  <label>Animal Name</label>
                  <input type="text" value={props.animal.name} disabled />
                </div>
              </div>
              <div className="input-row-container">
                <div style={{ width: '10%' }}>
                  <label>Sex</label>
                  <input type="text" value={props.animal.sex} disabled />
                </div>
                <div style={{ width: '90%' }}>
                  <label>Breed</label>
                  <input type="text" value={props.animal.breed} disabled />
                </div>
              </div>
              <div className="input-row-container">
                <div style={{ width: '100%' }}>
                  <label>Source</label>
                  <input type="text" value={props.animal.source} disabled />
                </div>
              </div>
              {props.animal.sire && (
                <div className="input-row-container">
                  <div style={{ width: '60%' }}>
                    <label>Verified Sire</label>
                    <input
                      type="text"
                      value={props.animal.sire?.name}
                      disabled
                    />
                  </div>
                  {/* <div>
                    <button className="fancy-button green">
                      <img src="./images/download-icon.svg" />
                      Download Test Results
                    </button>
                  </div> */}
                </div>
              )}
              {props.animal.dam && (
                <div className="input-row-container">
                  <div style={{ width: '60%' }}>
                    <label>Verified Dam</label>
                    <input
                      type="text"
                      value={props.animal.dam?.name}
                      disabled
                    />
                  </div>
                  {/* <div>
                    <button className="fancy-button green">
                      <img src="./images/download-icon.svg" />
                      Download Test Results
                    </button>
                  </div> */}
                </div>
              )}

              {progenyTable.getRowModel().rows.length > 0 && (
                <div className="input-row-container">
                  <div className="data-table" style={{ width: '100%' }}>
                    <label>Progeny</label>
                    <div className="table-container">
                      <table>
                        <thead>
                          {progenyTable.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                              {headerGroup.headers.map((header) => (
                                <th key={header.id} colSpan={header.colSpan}>
                                  {header.isPlaceholder ? null : (
                                    <div
                                      {...{
                                        className: 'column-header',
                                      }}
                                    >
                                      {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                    </div>
                                  )}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody>
                          {progenyTable.getRowModel().rows.map((row) => {
                            return (
                              <tr key={row.id}>
                                {row.getVisibleCells().map((cell) => {
                                  return (
                                    <td key={cell.id}>
                                      {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="flyout-body-topper">
                  <h4>Data</h4>
                  {/* <button className="fancy-button grey">
                    <img src="./images/download-icon-grey.svg" />
                    Download Whole Genome Genotypes
                  </button> */}
                </div>
                <div>
                  <div className="tabs">
                    {props.animal?.samples.map((sample) => (
                      <div
                        className={`tab${
                          sample.id === selectedSample?.id ? ' selected' : ''
                        }`}
                        key={sample.id}
                        onClick={() => setSelectedSample(sample)}
                      >
                        {sample.name}
                      </div>
                    ))}
                  </div>
                  <div className="panels data-table">
                    {props.animal?.samples.map((sample) => (
                      <div
                        className={`table-container${
                          sample.id === selectedSample?.id ? '' : ' hidden'
                        }`}
                        key={sample.id}
                      >
                        <table>
                          <thead>
                            {testTable.getHeaderGroups().map((headerGroup) => (
                              <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                  <th key={header.id} colSpan={header.colSpan}>
                                    {header.isPlaceholder ? null : (
                                      <div
                                        {...{
                                          className: 'column-header',
                                        }}
                                      >
                                        {flexRender(
                                          header.column.columnDef.header,
                                          header.getContext()
                                        )}
                                      </div>
                                    )}
                                  </th>
                                ))}
                              </tr>
                            ))}
                          </thead>
                          <tbody>
                            {testTable.getRowModel().rows.map((row) => {
                              return (
                                <tr key={row.id}>
                                  {row.getVisibleCells().map((cell) => {
                                    return (
                                      <td key={cell.id}>
                                        {flexRender(
                                          cell.column.columnDef.cell,
                                          cell.getContext()
                                        )}
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    ))}
                    <div className="row">
                      {/* <button className="fancy-button grey">
                        <img src="./images/sample-icon.svg" />
                        Submit a Sample
                      </button> */}
                    </div>
                    <div className="button-row">
                      <button
                        className="fancy-button grey"
                        onClick={() => handleCLoseFlyout()}
                      >
                        Close Animal Details
                      </button>
                      {/* <button className="fancy-button red">
                        <img src="./images/archive-icon.svg" />
                        Archive Animal
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
export default Flyout;
