// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".upload-button--ROZNtIfbMgXvGcc1q1Rk{margin-bottom:8px}", "",{"version":3,"sources":["webpack://./src/components/dashboad/dashboard.module.scss"],"names":[],"mappings":"AAEA,qCACI,iBAAA","sourcesContent":["@import 'src/scss/variables';\n\n.upload-button {\n    margin-bottom: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upload-button": "upload-button--ROZNtIfbMgXvGcc1q1Rk",
	"uploadButton": "upload-button--ROZNtIfbMgXvGcc1q1Rk"
};
export default ___CSS_LOADER_EXPORT___;
