import classNames from 'classnames';
import * as React from 'react';
import styles from './input.module.scss';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

export const Input = ({ className, name, label, ...rest }: InputProps) => {
  return (
    <div className={classNames(className, styles.inputField)}>
      <label htmlFor={name}>{label}:</label>
      <input {...rest} />
    </div>
  );
};
