import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App, { loader as appLoader } from './src/App';
import ErrorPage from './src/routes/error-page';
import Login, { loader as loginLoader } from './src/routes/login';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    loader: appLoader,
  },
  {
    path: 'login',
    element: <Login />,
    errorElement: <ErrorPage />,
    loader: loginLoader,
  },
]);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
