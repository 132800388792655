import * as React from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getExpandedRowModel,
  useReactTable,
  SortingState,
  PaginationState,
  getPaginationRowModel,
  ColumnDef,
} from '@tanstack/react-table';
import Flyout from './Flyout';
import { Card, CardSection } from '@kiwicom/orbit-components';
import { Animal, Page } from '@shared/types';
import { api } from '@shared/api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebouncedState, queryStringify } from '@shared/utils';
import { Button } from '@components/common';
import uploadIcon from '@images/upload-icon.svg';
import styles from './dashboard.module.scss';
import UploladModal from './UploladModal';

const columnHelper = createColumnHelper<Animal>();

const columns: ColumnDef<Animal, Animal[keyof Animal]>[] = [
  columnHelper.accessor((row) => row.id, {
    id: 'id',
    header: () => <span>Animal ID</span>,
    cell: (context) => <i>{context.getValue()}</i>,
  }),
  columnHelper.accessor('name', {
    cell: (context) => context.getValue(),
    header: () => <span>Animal Name</span>,
  }),
  columnHelper.accessor('breed', {
    header: () => 'Breed',
    cell: (context) => context.renderValue(),
  }),
  columnHelper.accessor('source', {
    header: () => 'Source',
    cell: (context) => context.renderValue(),
  }),
  columnHelper.accessor('sire', {
    header: () => <span>Verified Sire</span>,
    cell: (context) => context.renderValue()?.name,
  }),
  columnHelper.accessor('dam', {
    header: 'Verified Dam',
    cell: (context) => context.renderValue()?.name,
  }),
  columnHelper.accessor('samples', {
    header: 'Tests Performed',
    cell: (context) => (
      <div className="test-column">
        {context
          .getValue()
          .flatMap((sample) => sample.tests)
          .map((value, index) => {
            return (
              <div className="test-icon-container" key={index}>
                {' '}
                {getTestIcon(value.name)}
              </div>
            );
          })}
      </div>
    ),
  }),
  {
    id: 'actions',
    header: () => <></>,
    cell: (context) => (
      <div className="action px-1">
        <div
          className={`action-button-container${
            context.row.getIsExpanded() ? ' expanded' : ''
          }`}
        >
          <img className="action" src="./images/dots.svg" />
        </div>
        {context.row.getIsExpanded() && (
          <div className="action-popover popover-content">
            <ul className="popover-content">
              <li className="popover-content">View Animal Details</li>
              {/* <li className="popover-content">Delete Animal</li>
              <li className="popover-content">Submit a Sample</li> */}
            </ul>
          </div>
        )}
      </div>
    ),
  },
];

const getTestIcon = (test: string) => {
  switch (test.toLowerCase()) {
    case 'meat':
      return (
        <>
          <div className="popover">
            <span>{test} Test Performed</span>
            <p>Select Icon to view results</p>
            <div className="popover-arrow"></div>
          </div>
          <img src="./images/test-meat.svg" />
        </>
      );
    case 'parentage':
      return (
        <>
          <div className="popover">
            <span>{test} Test Performed</span>
            <p>Select Icon to view results</p>
            <div className="popover-arrow"></div>
          </div>
          <img src="./images/test-parentage.svg" />
        </>
      );
    case 'sire':
      return (
        <>
          <div className="popover">
            <span>{test} Test Performed</span>
            <p>Select Icon to view results</p>
            <div className="popover-arrow"></div>
          </div>
          <img src="./images/test-sire.svg" />
        </>
      );
    default:
      return (
        <>
          <div className="popover">
            <span>{test} Test Performed</span>
            <p>Select Icon to view results</p>
            <div className="popover-arrow"></div>
          </div>
          <img src="./images/test-other.svg" />
        </>
      );
  }
};

interface DashboardProps {
  animals: Page<Animal>;
}

type Filters = { id?: number; name?: string; source?: string };

function Dashboard({ animals: initialPage }: DashboardProps) {
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [page, setPage] = useState(initialPage);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [flyout, setFlyout] = useState<Animal | undefined>(undefined);
  const [filtersVisible, setFiltersVisible] = useState<boolean>(false);
  const [filters, setFilters, debouncedFilters] = useDebouncedState<Filters>(
    {}
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: page.number,
    pageSize: page.size,
  });

  const handleFilterChange = useCallback(
    (filters: Filters) => {
      setPagination({ pageIndex: 0, pageSize });
      setFilters(filters);
    },
    [pageSize, setFilters]
  );

  useEffect(() => {
    (async () => {
      const page: Page<Animal> = await api(
        'GET',
        `/animals?page=${pageIndex}&size=${pageSize}&${queryStringify(
          debouncedFilters
        )}`
      );
      setPage(page);
    })();
  }, [pageIndex, pageSize, debouncedFilters]);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    columns: columns,
    data: page.content,
    pageCount: page.totalPages,
    state: {
      sorting,
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    debugTable: true,
  });

  const handleAnimalFlyoutToggle = (animalId: number) => {
    table.toggleAllRowsExpanded(false);
    const animal = page.content.find((animal) => animal.id === animalId);
    setFlyout(animal);
  };

  const handleToggleFilterPanel = useCallback(() => {
    setFiltersVisible(!filtersVisible);
    setFilters({});
  }, [filtersVisible, setFilters]);

  return (
    <div className="page-content">
      <h1>Dashboard</h1>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          className={styles.uploadButton}
          icon={uploadIcon}
          label="Upload Files"
          size="lg"
          onClick={() => {
            setUploadModalVisible(true);
          }}
        />
      </div>
      {uploadModalVisible ? (
        <UploladModal
          onClose={() => setUploadModalVisible(false)}
          size={'small'}
        />
      ) : null}
      <div className="data-table">
        <Card title="Your Animals" titleAs="h2">
          <CardSection>
            <div className="filter-box">
              <h3>
                <img src="./images/filter.svg" />
                Filters
                <span
                  className={filtersVisible ? 'open' : undefined}
                  onClick={handleToggleFilterPanel}
                >
                  <img src="./images/filter-panel-chevron.svg" />
                </span>
              </h3>
              {filtersVisible && (
                <div className="filter-container input-row-container">
                  <div>
                    <label>Animal ID</label>
                    <input
                      value={filters.id ?? ''}
                      onChange={(e) =>
                        handleFilterChange({
                          ...filters,
                          id: e.target.value
                            ? Number(e.target.value)
                            : undefined,
                        })
                      }
                      type="text"
                      placeholder="Enter Animal ID"
                    />
                  </div>
                  <div>
                    <label>Animal Name</label>
                    <input
                      value={filters.name ?? ''}
                      onChange={(e) =>
                        handleFilterChange({ ...filters, name: e.target.value })
                      }
                      type="text"
                      placeholder="Enter Animal Name"
                    />
                  </div>
                  <div>
                    <label>Source</label>
                    <input
                      value={filters.source ?? ''}
                      onChange={(e) =>
                        handleFilterChange({
                          ...filters,
                          source: e.target.value,
                        })
                      }
                      type="text"
                      placeholder="Enter Source"
                    />
                  </div>
                </div>
              )}
            </div>
            <h2>Results</h2>
            <div className="table-container">
              <table>
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id} colSpan={header.colSpan}>
                          {header.isPlaceholder ? null : (
                            <div
                              {...{
                                className: header.column.getCanSort()
                                  ? 'column-header cursor-pointer select-none'
                                  : 'column-header',
                                onClick:
                                  header.column.getToggleSortingHandler(),
                              }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              <span
                                style={{
                                  display: 'inline-flex',
                                  width: '10px',
                                }}
                              >
                                {{
                                  asc: (
                                    <img
                                      className="sort-arrow"
                                      src="./images/up-arrow.svg"
                                    />
                                  ),
                                  desc: (
                                    <img
                                      className="sort-arrow"
                                      src="./images/down-arrow.svg"
                                    />
                                  ),
                                }[header.column.getIsSorted() as string] ??
                                  null}
                              </span>
                            </div>
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => {
                    return (
                      <tr
                        key={row.id}
                        className={`hoverable${
                          row.getIsSelected() ? ' selected' : ''
                        }`}
                        onClick={(event) => {
                          (event.target as HTMLTextAreaElement).type !==
                            'checkbox' &&
                            ((
                              event.target as HTMLTextAreaElement
                            ).classList.contains('action') ||
                            (
                              event.target as HTMLTextAreaElement
                            ).classList.contains('action-button-container')
                              ? [
                                  table.toggleAllRowsExpanded(false),
                                  !row.getIsExpanded()
                                    ? row.toggleExpanded(true)
                                    : null,
                                ]
                              : [
                                  table.toggleAllRowsExpanded(false),
                                  handleAnimalFlyoutToggle(row?.original?.id),
                                ]);
                        }}
                      >
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <td key={cell.id}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="table-footer">
                <div className="table-footer-status">
                  <div>
                    Showing{' '}
                    {table.getState().pagination.pageIndex *
                      table.getState().pagination.pageSize +
                      1}{' '}
                    -{' '}
                    {(table.getState().pagination.pageIndex + 1) *
                      table.getState().pagination.pageSize <
                    page.totalElements
                      ? (table.getState().pagination.pageIndex + 1) *
                        table.getState().pagination.pageSize
                      : page.totalElements}
                    <span> of {page.totalElements}</span>
                  </div>
                </div>
                <div className="table-footer-pagination">
                  <button
                    className="border rounded p-1"
                    onClick={() => table.setPageIndex(0)}
                    disabled={!table.getCanPreviousPage()}
                    title="Go to beginning"
                  >
                    {'<<'}
                  </button>
                  <button
                    className="border rounded p-1"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                    title="Previous"
                  >
                    {'<'}
                  </button>
                  <button
                    className="border rounded p-1"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                    title="Next"
                  >
                    {'>'}
                  </button>
                  <button
                    className="border rounded p-1"
                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                    disabled={!table.getCanNextPage()}
                    title="Go to end"
                  >
                    {'>>'}
                  </button>
                </div>
                <div className="table-footer-rows-selector">
                  <select
                    value={table.getState().pagination.pageSize}
                    onChange={(e) => {
                      table.setPageSize(Number(e.target.value));
                    }}
                  >
                    {[2, 10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize} Items per Page
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </CardSection>
        </Card>
      </div>
      <Flyout animal={flyout} closeFlyout={() => setFlyout(undefined)} />
    </div>
  );
}

export default Dashboard;
