import Alert from '@kiwicom/orbit-components/lib/Alert';
import * as React from 'react';
import { FormEvent, useState } from 'react';
import { redirect, useNavigate } from 'react-router-dom';
import { Button, Input } from '../components/common';
import { api } from '../shared/api';
import { SessionService } from '../shared/SessionService';
import { AuthResult } from '../shared/types';

import styles from './login.module.scss';

export async function loader() {
  if (SessionService.hasToken) {
    throw redirect('/');
  }
  return null;
}

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (username && password) {
      try {
        const { authToken }: AuthResult = await api('POST', '/login', {
          username: username,
          password: password,
        });
        SessionService.token = authToken;
      } catch (ex) {
        console.error(ex);
        setError(`${ex?.message ?? ex}`);
      }
      navigate('/');
    }
  };

  return (
    <div className={styles.loginPage}>
      <div className={styles.header}>Log in to My Ranch</div>

      {error ? (
        <div className={styles.alertContainer}>
          <Alert icon type="critical" closable onClose={() => setError('')}>
            {error}
          </Alert>
        </div>
      ) : null}

      <form onSubmit={handleSubmit}>
        <Input
          label="Username"
          name="username"
          autoComplete="username"
          value={username}
          onChange={(event) => setUsername(event.target.value)}
        />
        <Input
          className={styles.password}
          label="Password"
          name="password"
          type="password"
          autoComplete="current-password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
        <Button
          type="submit"
          style={{ width: '100%' }}
          label="Log In"
          size="lg"
        />
      </form>
    </div>
  );
};

export default Login;
