import defaultTheme from '@kiwicom/orbit-components/lib/defaultTheme';

// import ThemeProvider from '@kiwicom/orbit-components/lib/ThemeProvider';
import * as React from 'react';
import { redirect, useLoaderData } from 'react-router-dom';
import Dashboard from '@components/dashboad/Dashboard';
import Header from '@components/Header';
import './scss/main.scss';
import { api } from '@shared/api';
import { SessionService } from '@shared/SessionService';
import { Animal, Page } from '@shared/types';
import OrbitProvider from '@kiwicom/orbit-components/lib/OrbitProvider';
import { getTokens } from '@kiwicom/orbit-components';

export async function loader() {
  if (!SessionService.hasToken) {
    throw redirect('/login');
  }
  const animals: Page<Animal> = await api('GET', '/animals?page=0&size=10');
  return { animals };
}

type LoaderData = Awaited<ReturnType<typeof loader>>;

function App() {
  const { animals } = useLoaderData() as LoaderData;
  return (
    <OrbitProvider
      theme={{
        ...defaultTheme,
        orbit: getTokens({
          base: {
            // override the default font Roboto with Mulish
            // fontFamily:
            //   "'Roboto', -apple-system, '.SFNSText-Regular', 'San Francisco', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande', sans-serif",
            fontFamily:
              "'Mulish', -apple-system, '.SFNSText-Regular', 'San Francisco', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande', sans-serif",
          },
        }),
      }}
    >
      <main>
        <Header />
        <Dashboard animals={animals} />
      </main>
    </OrbitProvider>
  );
}
export default App;
