export const queryStringify = (
  object: Record<string, unknown> | Array<unknown>,
  objectName?: string
): string => {
  if (Array.isArray(object)) {
    return object
      .map((val, index) => {
        const name = objectName ? objectName + '[]' : index + '';
        return val != null && typeof val === 'object'
          ? queryStringify(val, name)
          : name + '=' + encodeURIComponent(((val ?? '') + '').trim());
      })
      .join('&');
  }
  return Object.entries(object)
    .filter(([_, val]) => val != null)
    .map(([key, val]) => {
      const name = objectName ? objectName + '[' + key + ']' : key;
      if (val != null && typeof val === 'object') {
        if (val instanceof Date) {
          return name + '=' + val.toISOString();
        }
        return queryStringify(
          val as Record<string, unknown> | Array<unknown>,
          name
        );
      }
      return name + '=' + encodeURIComponent(((val ?? '') + '').trim());
    })
    .join('&');
};
