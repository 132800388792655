// import { Select, SelectProps } from '@components/common';
import Modal, {
  ModalHeader,
  ModalSection,
} from '@kiwicom/orbit-components/lib/Modal';
import { Props as ModalProps } from '@kiwicom/orbit-components/lib/Modal/types';

import InputFile from '@kiwicom/orbit-components/lib/InputFile';
import React, { FormEvent, useCallback, useState } from 'react';
import styles from './upload-modal.module.scss';
import Select from '@kiwicom/orbit-components/lib/Select';
import { Props as SelectProps } from '@kiwicom/orbit-components/lib/Select/types';
import { Button, UserMessage } from '@components/common';
import { api } from '@shared/api';
import Alert from '@kiwicom/orbit-components/lib/Alert';

const fileTypeOptions: SelectProps['options'] = [
  {
    label: 'Animal Entry',
    value: 'AnimalEntry',
  },
  {
    label: 'Sample Identity',
    value: 'SampleIdentity',
  },
  {
    label: 'Parentage Verification',
    value: 'ParentageVerification',
  },
];

export default function UploladModal(props: Omit<ModalProps, 'children'>) {
  const [fileType, setFileType] = useState('');
  const [file, setFile] = useState<File | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userMessage, setUserMessage] = useState<UserMessage | undefined>(
    undefined
  );

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!fileType || !file) return;
      const formData = new FormData();
      formData.append('fileType', fileType);
      formData.append('file', file);
      try {
        setIsSubmitting(true);
        await api('POST', '/samples', formData);
        setUserMessage({
          type: 'success',
          content: `Upload processed successfully!`,
        });
      } catch (ex) {
        console.error(ex);
        setUserMessage({
          type: 'critical',
          content: `${ex?.message ?? ex}`,
        });
      } finally {
        setIsSubmitting(false);
      }
    },
    [file, fileType]
  );

  return (
    <Modal {...props}>
      <ModalHeader title="Upload Files" />
      <ModalSection>
        {userMessage ? (
          <div className={styles.alertContainer}>
            <Alert
              icon
              type={userMessage.type}
              closable
              onClose={() => setUserMessage(undefined)}
            >
              {userMessage.content}
            </Alert>
          </div>
        ) : null}

        <form onSubmit={handleSubmit}>
          <div className={styles.selectContainer}>
            <Select
              placeholder="Choose the file type"
              options={fileTypeOptions}
              label="File Type"
              name="fileType"
              value={fileType}
              onChange={(event) => setFileType(event.currentTarget.value)}
            />
          </div>

          <div className={styles.inputFileContainer}>
            <InputFile
              name="file"
              fileName={file?.name}
              allowedFileTypes={['.xls', '.xlsx']}
              onChange={(event) =>
                setFile(event.currentTarget.files?.[0] ?? undefined)
              }
              onRemoveFile={() => setFile(undefined)}
            />
          </div>
          <Button
            type="submit"
            style={{ width: '100%' }}
            label="Submit"
            size="lg"
            disabled={!fileType || !file || isSubmitting}
          />
        </form>
      </ModalSection>
    </Modal>
  );
}
