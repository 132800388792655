import { SessionService } from './SessionService';
import { last } from './utils';

export const apiRoutes = {
  login: '/login',
} as const;

export const getFullApiUrl = (endpoint: string): string =>
  `${process.env.API_HOST ?? ''}${endpoint}`;

export async function api<Result, Payload = unknown>(
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE',
  endpoint: string,
  body?: Payload,
  customHeaders: Record<string, string> = {},
  useJson = true
): Promise<Result> {
  const token = SessionService.token;
  const headers = new Headers();
  const isMultiPart = body instanceof FormData;

  if (!isMultiPart) {
    headers.append('Content-Type', 'application/json');
  }

  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
  }

  if (customHeaders) {
    for (const header in customHeaders) {
      headers.append(header, customHeaders[header]);
    }
  }

  const config: RequestInit = {
    method: method,
    headers,
  };

  if (body) {
    config.body = isMultiPart ? body : JSON.stringify(body);
  }

  const response = await fetch(getFullApiUrl(endpoint), config);
  if (response.status === 204) return true as Result;
  if (!useJson) return (await response.blob()) as Result;
  let data;
  try {
    data = await response.json();
  } catch (error) {
    console.log(error);
    data = { errors: [error.toString()] };
  }
  if (method === 'DELETE' && response.status === 204) {
    // infer an id from the endpoint if we've just deleted something
    return Number(last(endpoint.split('/'))) as Result;
  }
  if (response.status === 401) {
    SessionService.token = null;
    if (window.location.pathname !== apiRoutes.login) {
      const { pathname, search, hash } = window.location;
      SessionService.loginRedirect = pathname + search + hash;
      window.location.href = apiRoutes.login;
    }
    throw new Error(data.message ?? 'Unauthorized');
  }
  if (response.status >= 400) {
    throw new Error(data.message ?? data);
  }
  return await data;
}
