import * as React from 'react';
import Input from '@kiwicom/orbit-components/lib/InputField';
import {
  AccountCircle,
  Logout,
  Search,
} from '@kiwicom/orbit-components/lib/icons';
import { Link, redirect, useNavigate } from 'react-router-dom';
import { SessionService } from '../shared/SessionService';
import { useCallback } from 'react';

import styles from './header.module.scss';
import classNames from 'classnames';

function Header() {
  let navigate = useNavigate();

  const logout = useCallback(() => {
    SessionService.token = null;
    navigate('/login');
  }, []);

  return (
    <div className="header-container">
      <div className="logo-container">My Ranch</div>
      {/* <div className="search-bar-container">
        <Input
          inputMode="search"
          placeholder="Search your database"
          prefix={<Search />}
        />
      </div> */}
      <div className="header-controls-container">
        <div className="header-greeting-container">
          <div className="header-greeting-text">Welcome User!</div>
          <a
            className={classNames('header-greeting-logout', styles.logoutLink)}
            href=""
            onClick={logout}
          >
            <Logout />
            Logout
          </a>
        </div>
        <div className="header-avatar">
          <AccountCircle />
        </div>
        {/* <div className="header-notifications">
          <img src="./images/notifications.svg" />
        </div> */}
      </div>
    </div>
  );
}
export default Header;
