// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "a.logout-link--Z330fZ9tIXhcJQKyJhm3{color:#ecf6f2;text-decoration:none}", "",{"version":3,"sources":["webpack://./src/components/header.module.scss","webpack://./src/scss/_variables.scss"],"names":[],"mappings":"AAEA,oCACI,aCUc,CDTd,oBAAA","sourcesContent":["@import 'src/scss/variables';\n\na.logout-link{\n    color: $color-white-ish;\n    text-decoration: none;\n}","$font-DM-serif-display: 'DM Serif Display';\n$font-mulish: 'Mulish';\n\n$color-baby-blue: #BAC7D5;\n$color-dark-blue: #25274D;\n$color-green: #1D8046;\n$color-grey: #eeeeee;\n$color-grey-table-bg: #F5F5F5;\n$color-light-green: #EEF5F1;\n$color-background-grey: #A9ABB8;\n$color-pale-grey: #f9f9fc;\n$color-paler-grey: #E2E3EF;\n$color-white: #FFFFFF;\n$color-white-ish: #ECF6F2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logout-link": "logout-link--Z330fZ9tIXhcJQKyJhm3",
	"logoutLink": "logout-link--Z330fZ9tIXhcJQKyJhm3"
};
export default ___CSS_LOADER_EXPORT___;
