const AUTH_TOKEN = '__app_session_token__';
const LOGIN_REDIRECT = '__app_login_redirect';

export const SessionService = {
  get hasToken(): boolean {
    return this.token !== null;
  },
  get token(): string | null {
    return window.localStorage.getItem(AUTH_TOKEN);
  },
  set token(token: string | null) {
    if (token == null) {
      window.localStorage.removeItem(AUTH_TOKEN);
    } else {
      window.localStorage.setItem(AUTH_TOKEN, token);
    }
  },
  get loginRedirect(): string | null {
    return window.localStorage.getItem(LOGIN_REDIRECT);
  },
  set loginRedirect(path: string | null) {
    if (path == null) {
      window.localStorage.removeItem(LOGIN_REDIRECT);
    } else {
      window.localStorage.setItem(LOGIN_REDIRECT, path);
    }
  },
};
