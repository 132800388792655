import { useState, useEffect, Dispatch, SetStateAction } from 'react';

/**
 * A custom hook that behaves like React.useState but also includes the
 * debounced value of the state in the returned tuple
 *
 * @param value
 * @param timeoutInMillis
 */
export const useDebouncedState = <T>(
  initialValue: T,
  timeoutInMillis = 500
): [T, Dispatch<SetStateAction<T>>, T] => {
  const [value, setValue] = useState<T>(initialValue);
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, timeoutInMillis);
    return () => {
      clearTimeout(handler);
    };
  }, [timeoutInMillis, value]);
  return [value, setValue, debouncedValue];
};
