// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login-page--KVQWX1sf0568rLbwQVzk{display:flex;flex-direction:column;align-items:center;justify-content:center;height:100vh;font-family:\"Mulish\"}.login-page--KVQWX1sf0568rLbwQVzk .header--oJRI4tuEH3ClUm44P7nj{padding-bottom:18px;font-size:2rem;letter-spacing:-0.5px}.login-page--KVQWX1sf0568rLbwQVzk .alert-container--UeZ7jnh9IT9ndC35cN_6{width:440px;padding-bottom:18px}.login-page--KVQWX1sf0568rLbwQVzk form{width:400px;padding:20px;border:1px solid #ccc;border-radius:10px;box-shadow:2px 2px 10px rgba(0,0,0,.1)}.login-page--KVQWX1sf0568rLbwQVzk .password--fDFT1I73wj0cjRBy9fnO{padding:18px 0}", "",{"version":3,"sources":["webpack://./src/routes/login.module.scss","webpack://./src/scss/_variables.scss"],"names":[],"mappings":"AAEA,kCACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,YAAA,CACA,oBCPY,CDSZ,gEACE,mBAAA,CACA,cAAA,CACA,qBAAA,CAGF,yEACE,WAAA,CACA,mBAAA,CAGF,uCACE,WAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sCAAA,CAGF,kEACE,cAAA","sourcesContent":["@import 'src/scss/variables';\n\n.login-page {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  font-family: $font-mulish;\n  \n  .header {\n    padding-bottom: 18px;\n    font-size: 2rem;\n    letter-spacing: -0.5px;\n  }\n  \n  .alert-container{\n    width: 440px;\n    padding-bottom: 18px;\n  }\n\n  form {\n    width: 400px;\n    padding: 20px;\n    border: 1px solid #ccc;\n    border-radius: 10px;\n    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);\n  }\n\n  .password {\n    padding: 18px 0;\n  }\n}\n","$font-DM-serif-display: 'DM Serif Display';\n$font-mulish: 'Mulish';\n\n$color-baby-blue: #BAC7D5;\n$color-dark-blue: #25274D;\n$color-green: #1D8046;\n$color-grey: #eeeeee;\n$color-grey-table-bg: #F5F5F5;\n$color-light-green: #EEF5F1;\n$color-background-grey: #A9ABB8;\n$color-pale-grey: #f9f9fc;\n$color-paler-grey: #E2E3EF;\n$color-white: #FFFFFF;\n$color-white-ish: #ECF6F2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login-page": "login-page--KVQWX1sf0568rLbwQVzk",
	"loginPage": "login-page--KVQWX1sf0568rLbwQVzk",
	"header": "header--oJRI4tuEH3ClUm44P7nj",
	"alert-container": "alert-container--UeZ7jnh9IT9ndC35cN_6",
	"alertContainer": "alert-container--UeZ7jnh9IT9ndC35cN_6",
	"password": "password--fDFT1I73wj0cjRBy9fnO"
};
export default ___CSS_LOADER_EXPORT___;
