// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".error-page--FDgFnVrEp5yrGoq3U2Xc{text-align:center}", "",{"version":3,"sources":["webpack://./src/routes/error-page.module.scss"],"names":[],"mappings":"AAAA,kCACE,iBAAA","sourcesContent":[".error-page {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error-page": "error-page--FDgFnVrEp5yrGoq3U2Xc",
	"errorPage": "error-page--FDgFnVrEp5yrGoq3U2Xc"
};
export default ___CSS_LOADER_EXPORT___;
